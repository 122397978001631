#footerChico{
    background-color: #00000080;
}

#footerColor{
    background-color: #10273d;
}
Footer{
    color: #00000080;
}
#follow{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}
ul li{
    list-style-type: none;
}
ul{
    display: inline-block;
}
